import React from "react";
import {
  SubLayout,
  ClinicsParticle,
  CareerParticle,
  ContactParticle,
  News,
} from "../components";
import Seo from "../components/Seo";
// import Seo from "../components/seo";

const NewsPage = () => {
  return (
    <SubLayout>
      <Seo
        title="お知らせ"
        description="医療法人社団慶育会からのお知らせやスタッフによるブログを発信していきます。"
        keywords="医療法人社団 慶育会,グレースメディカルグループ,在宅医療,訪問診療,訪問医療,お知らせ,ブログ,NEWS,BLOG"
      />
      <News />
      <ClinicsParticle />
      <CareerParticle />
      <ContactParticle />
    </SubLayout>
  );
};

export default NewsPage;
